import type { ZodIssue } from 'zod'
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { CRUDModes } from '~/types'

type OptionsToastMaker = {
  entity: string
  mode: CRUDModes
}

const formatTrpcErrorErrorMessage = (error: Error | null): string => {
  if (!error || !error.message) {
    return 'unbekannt'
  }

  try {
    // Note: trpc returns zod errors as stringified json
    const zodErrors = JSON.parse(error.message)
    return zodErrors.map((zodError: ZodIssue) => {
      return `${zodError.message} '/${zodError.path.join('/')}'`
    }).join(', ')
  } catch {
    return error.message
  }
}

export default () => {
  const { $i18n } = useNuxtApp()

  const makeTrpcErrorToast = (options: OptionsToastMaker, callback?: () => void) => (error: Error | null) => {
    const title = $i18n.t('notifications_error_tryAgain')
    const content = $i18n.t(`notifications.error.${options.mode}`, { entity: options.entity })

    notify('error', title, content)
    console.error(`Error: ${formatTrpcErrorErrorMessage(error)}`)
    if (callback) {
      callback()
    }
  }

  const makeTrpcSuccessToast = (options: OptionsToastMaker, text?: string) => {
    const title = $i18n.t(`notifications.success.${options.mode}`, { entity: options.entity })
    notify('success', title, text)
  }

  const { $trpc } = useNuxtApp()
  const queryClient = useQueryClient()
  return {
    $trpc,
    queryClient,
    useMutation,
    makeTrpcErrorToast,
    makeTrpcSuccessToast,
  }
}
